<template>
  <div>
    <client-only>
      <div v-if="isLoading">
        <TransitionRoot
          :show="isLoading"
          enter="transition-opacity duration-75"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <full-screen-loading-hippo />
        </TransitionRoot>
      </div>
      <div v-if="isAuthenticated">
        <TransitionRoot
          :show="isAuthenticated"
          enter="transition-opacity duration-75"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <slot></slot>
        </TransitionRoot>
      </div>
    </client-only>
  </div>
</template>

<script setup>
import { TransitionRoot } from "@headlessui/vue";

const session = useSessionStore();
const { isLoading, isAuthenticated } = storeToRefs(session);

const router = useRouter();
watchEffect(() => {
  if (!isLoading.value && !isAuthenticated.value) {
    router.push("/unauthorized");
  }
});
</script>
